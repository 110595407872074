import { NoParamEndpointConstructor, OneParamEndpointConstructor } from 'interfaces/api.interfaces'

interface Endpoints {
  getAttributeTypes: NoParamEndpointConstructor
  getOneAttributeType: OneParamEndpointConstructor
  createAttributeType: NoParamEndpointConstructor
  deleteAttributeType: OneParamEndpointConstructor
  updateAttributeType: OneParamEndpointConstructor
  getAttributeValues: OneParamEndpointConstructor
  getOneAttributeValue: OneParamEndpointConstructor
  createAttributeValue: OneParamEndpointConstructor
  deleteAttributeValue: OneParamEndpointConstructor
  updateAttributeValue: OneParamEndpointConstructor
}

export const attributesEndpoints: Endpoints = {
  getAttributeTypes: () => 'admin/attribute-types/',
  getOneAttributeType: (id) => `admin/attribute-types/${id}`,
  createAttributeType: () => 'admin/attribute-types/',
  deleteAttributeType: (id) => `admin/attribute-types/${id}`,
  updateAttributeType: (id) => `admin/attribute-types/${id}`,
  getAttributeValues: (id) => `admin/attribute-types/${id}/attribute-values`,
  getOneAttributeValue: (id) => `admin/attribute-values/${id}`,
  createAttributeValue: (id) => `admin/attribute-types/${id}/attribute-values`,
  deleteAttributeValue: (id) => `admin/attribute-values/${id}`,
  updateAttributeValue: (id) => `admin/attribute-values/${id}`,
}
