import { useCallback, useEffect, useRef } from 'react'

import {
  DefaultError,
  QueryFunctionContext,
  QueryKey,
  QueryObserverResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import { GetAllData } from 'interfaces/api.interfaces'
import { flatten, last } from 'lodash'
import { defaultPaginationModel, PaginationResult } from 'packages/react-query/src/defaultPaginationModel'
import { PaginateData, UsePaginateQueryOptions } from 'packages/react-query/src/types'

import { useQueryClient } from './useQueryClient'

export interface PagesMethods<T = unknown> {
  addPage(data: T, pageParam: number): PagesObject<T>
  map<U>(cb: (value: T, index: number, array: T[]) => U, thisArg?: any): U[]
  mapPages(cb: (value: T, index: number, pages: Pages<T>) => T): Pages<T>
  find(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): T | undefined
  getFirstPage(): T
  getLastPage(): T
  removeOtherPages(page: number): void
}

export type PagesObject<T = unknown> = Record<number, T> & PagesMethods<T>

class Pages<T = unknown> implements PagesMethods<T> {
  constructor(data: T, pageParam: number) {
    const pages = this as any
    pages[pageParam] = data

    pages[Symbol.iterator] = function () {
      const keys = Object.keys(this)
      let index = 0

      return {
        next: () => ({
          value: this[keys[index]],
          done: index++ === this.getMaxPage(),
        }),
      }
    }

    Object.defineProperty(pages, 'length', {
      get() {
        return Object.keys(this).length
      },
    })
  }

  addPage(data: T, pageParam: number) {
    if (typeof pageParam === 'undefined') {
      return this
    }
    const pages = this as any
    pages[pageParam] = data
    return pages
  }

  map<U>(cb: (value: T, index: number, array: T[]) => U, thisArg?: any): U[] {
    return Object.entries(this)
      .sort((a, b) => Number(a[0]) - Number(b[0]))
      .map(
        (value, _index, array) =>
          cb(
            value[1],
            Number(value[0]),
            array.map((item) => item[1]),
          ),
        thisArg,
      )
  }

  mapPages(cb: (value: T, index: number, pages: Pages<T>) => T): Pages<T> {
    return Object.entries(this)
      .sort((a, b) => Number(a[0]) - Number(b[0]))
      .reduce(
        (pages: Pages<T>, [key, list]) => {
          delete (pages as any).undefined
          pages.addPage(cb(list, Number(key), pages), Number(key))
          return pages
        },
        new Pages(undefined, undefined!) as Pages<T>,
      )
  }

  find(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): T | undefined {
    return Object.entries(this)
      .sort((a, b) => Number(a[0]) - Number(b[0]))
      .find(
        (value, _index, array) =>
          predicate(
            value[1],
            Number(value[0]),
            array.map((item) => item[1]),
          ),
        thisArg,
      )?.[1]
  }

  getMinPage() {
    return Math.min(...Object.keys(this).map((key) => Number(key)))
  }

  getMaxPage() {
    return Math.max(...Object.keys(this).map((key) => Number(key)))
  }

  getFirstPage() {
    const firstPage = Math.min(...Object.keys(this).map((key) => Number(key)))
    return (this as any)[firstPage]
  }

  getLastPage() {
    const lastPage = Math.max(...Object.keys(this).map((key) => Number(key)))
    return (this as any)[lastPage]
  }

  removeOtherPages(page: number) {
    Object.entries(this).forEach((item) => {
      if (Number(item[0]) !== page) {
        delete (this as any)[item[0]]
      }
    })
  }
}

export const usePaginateQuery = <
  Item = unknown,
  TData extends GetAllData<Item> | undefined = GetAllData<Item>,
  TError = DefaultError,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UsePaginateQueryOptions<TData, TError, TData, TQueryKey> & { perPage: number },
) => {
  type Results = Exclude<TData, undefined>['data']
  const refOptions = useRef(options)
  refOptions.current = options
  const getQueryKey = useCallback(() => refOptions.current.queryKey, [])
  const queryClient = useQueryClient()
  const paginationModel = defaultPaginationModel

  const getCurrentPage = useCallback(
    () => queryClient.getQueryData<PaginateData<TData>>(getQueryKey())?.currentPage ?? 1,
    [],
  )

  const setCurrentPage = useCallback((newPage: number, newQueryKey = getQueryKey()) => {
    if (queryClient.getQueryData<PaginateData<TData>>(getQueryKey())?.pages) {
      queryClient.setQueryData<PaginateData<TData>>(newQueryKey, (state) => {
        state.currentPage = newPage
      })
    }
  }, [])

  const getIsRefetching = useCallback(
    () => !!queryClient.getQueryData<PaginateData<TData>>(getQueryKey())?.isRefetching,
    [],
  )

  const setIsRefetching = useCallback((isRefetching: boolean) => {
    if (queryClient.getQueryData<PaginateData<TData>>(getQueryKey())?.pages) {
      queryClient.setQueryData<PaginateData<TData>>(getQueryKey(), (state) => {
        state.isRefetching = isRefetching
      })
    }
  }, [])

  const getIsFetchingNextPage = useCallback(
    () => !!queryClient.getQueryData<PaginateData<TData>>(getQueryKey())?.isFetchingNextPage,
    [],
  )

  const setIsFetchingNextPage = useCallback((isFetchingNextPage: boolean) => {
    if (queryClient.getQueryData<PaginateData<TData>>(getQueryKey())?.pages) {
      queryClient.setQueryData<PaginateData<TData>>(getQueryKey(), (state) => {
        state.isFetchingNextPage = isFetchingNextPage
      })
    }
  }, [])

  const getIsFetchingAllPages = useCallback(
    () => !!queryClient.getQueryData<PaginateData<TData>>(getQueryKey())?.isFetchingAllPages,
    [],
  )

  const setIsFetchingAllPages = useCallback((isFetchingAllPages: boolean) => {
    if (queryClient.getQueryData<PaginateData<TData>>(getQueryKey())?.pages) {
      queryClient.setQueryData<PaginateData<TData>>(getQueryKey(), (state) => {
        state.isFetchingAllPages = isFetchingAllPages
      })
    }
  }, [])

  const setOnSuccessFetchingPage = useCallback((onSuccessFetchingPage: (() => void) | null) => {
    if (queryClient.getQueryData<PaginateData<TData>>(getQueryKey())?.pages) {
      queryClient.setQueryData<PaginateData<TData>>(getQueryKey(), (state) => {
        state.onSuccessFetchingPage = onSuccessFetchingPage
      })
    }
  }, [])

  const result = useQuery<TData, TError, TData, TQueryKey>({
    ...options,
    refetchInterval: false,
    queryFn: (async (params: QueryFunctionContext<TQueryKey, number>) => {
      const pageParam = getCurrentPage()
      const queryKey = getQueryKey()
      const data = await refOptions.current.queryFn?.(
        Object.assign(params, {
          pageParam: getCurrentPage(),
          direction: 'forward',
        }),
      )
      queryClient.setQueryData<PaginateData<TData>>(queryKey, (state) => {
        if (!state) {
          return undefined
        }
        state.pages = state.pages ? (state.pages.addPage(data as TData, pageParam) as any) : new Pages(data, pageParam)
      })
      queryClient.getQueryData<PaginateData<TData>>(queryKey)?.onSuccessFetchingPage?.(data)
      return {
        pageParam,
        data,
      }
    }) as any,
    structuralSharing: (oldResults, newResults) => {
      const oldData = oldResults as PaginateData<TData> | undefined
      const newData = newResults as {
        pageParam?: number
        data: GetAllData<unknown>
        pages?: Pages
        currentPage?: number
        isRefetching?: boolean
        isFetchingNextPage?: boolean
        isFetchingAllPages?: boolean
        onSuccessFetchingPage: ((data: TData | undefined) => void) | null
      }

      // Для работы ручной установки значений через queryClient.setQueryData
      if (newData.pages && !newData.pageParam && newData.currentPage === undefined) {
        return {
          ...oldData,
          pages: newData.pages,
        } as any
      }

      if (!newData.data && !newData.pages) {
        return
      }
      if (!newData.pageParam) {
        return {
          pages: oldData ? oldData?.pages : new Pages(newData.data || [], 1),
          currentPage: newData.currentPage,
          isRefetching: !!newData.isRefetching,
          isFetchingNextPage: !!newData.isFetchingNextPage,
          isFetchingAllPages: !!newData.isFetchingAllPages,
          onSuccessFetchingPage: newData?.onSuccessFetchingPage,
        }
      }
      return {
        pages: oldData ? oldData.pages : new Pages(newData.data, newData.pageParam),
        currentPage: newData.pageParam,
        isRefetching: false,
        isFetchingNextPage: false,
        isFetchingAllPages: false,
        onSuccessFetchingPage: oldData?.onSuccessFetchingPage,
      } as any
    },
  }) as UseQueryResult<PaginateData<TData>, TError>

  const refResult = useRef(result)
  refResult.current = result

  const isEnabled = useCallback(
    () => typeof refOptions.current.enabled === 'undefined' || refOptions.current.enabled,
    [],
  )
  const getNextPageParam = useCallback(
    (lastPage: TData) => paginationModel(lastPage as GetAllData<any>)?.next || undefined,
    [],
  )
  const lastPage =
    refResult.current.data?.pages.getLastPage?.() || last(refResult.current.data?.pages as unknown as any[])
  const hasNextPage = lastPage ? !!getNextPageParam(lastPage) : false

  const fetchPage = useCallback(
    (page: number, cb?: (result?: QueryObserverResult<PaginateData<TData>, TError>) => void, onError?: () => void) => {
      if (isEnabled()) {
        setCurrentPage(page)
        if (!refResult.current.data?.pages?.[page]) {
          refResult.current.refetch().then(cb).catch(onError)
        }
      }
    },
    [],
  )

  const refetchPage = useCallback(
    (page: number, cb?: (result?: QueryObserverResult<PaginateData<TData>, TError>) => void, onError?: () => void) => {
      if (!getIsRefetching() && isEnabled()) {
        setCurrentPage(page)
        setIsRefetching(true)
        refResult.current.refetch().then(cb).catch(onError)
      }
    },
    [],
  )

  const refetchCurrentPage = useCallback(
    (cb?: (result?: QueryObserverResult<PaginateData<TData>, TError>) => void, onError?: () => void) => {
      if (!getIsRefetching() && isEnabled()) {
        setIsRefetching(true)
        refResult.current.refetch().then(cb).catch(onError)
      }
    },
    [],
  )

  const getTotal = useCallback(() => {
    const data = queryClient.getQueryData<PaginateData<TData>>(getQueryKey())
    return (
      paginationModel(data?.pages.getFirstPage())?.count || (lastPage ? paginationModel(lastPage)?.count || 0 : 0) || 0
    )
  }, [])

  const fetchNextPage = useCallback(
    (cb?: (result?: QueryObserverResult<PaginateData<TData>, TError>) => void, onError?: () => void) => {
      if (getIsFetchingNextPage()) {
        return
      }
      const total = getTotal()
      const perPage = refOptions.current?.perPage
      const maxPage = perPage ? Math.ceil(total / perPage) : 0
      if (getCurrentPage() < maxPage) {
        setIsFetchingNextPage(true)
        fetchPage(
          getCurrentPage() + 1,
          (data) => {
            setIsFetchingNextPage(false)
            cb?.(data)
          },
          () => {
            setIsFetchingNextPage(false)
            onError?.()
          },
        )
      }
    },
    [],
  )

  const fetchAllPages = useCallback(async (): Promise<Results | undefined> => {
    if (getIsFetchingAllPages()) {
      return
    }
    const total = getTotal()
    const perPage = refOptions.current?.perPage
    const maxPage = perPage ? Math.ceil(total / perPage) : 0
    const pages = refResult.current.data?.pages ? Object.keys(refResult.current.data.pages).map(Number) : []

    if (pages.length >= maxPage) {
      return flatten(
        refResult.current.data?.pages.map((page) => (paginationModel(page) as PaginationResult<Item>).results) || [],
      )
    }
    setIsFetchingAllPages(true)
    return new Promise(async (resolve, reject) => {
      let successes = pages.length
      const onSuccess = () => {
        successes++
        if (successes >= maxPage) {
          resolve(null)
        }
      }
      setOnSuccessFetchingPage(onSuccess)
      for (let current = 1; current <= maxPage; current++) {
        if (pages.includes(current)) {
          continue
        }
        fetchPage(current, undefined, reject)
      }
    })
      .then(() =>
        flatten(
          refResult.current.data?.pages.map((page) => (paginationModel(page) as PaginationResult<Item>).results) || [],
        ),
      )
      .finally(() => {
        setIsFetchingAllPages(false)
        setOnSuccessFetchingPage(null)
      })
  }, [])

  const removeOtherPages = useCallback((page: number) => refResult.current.data?.pages.removeOtherPages(page), [])

  const reloadPages = useCallback((cb?: (result?: QueryObserverResult<PaginateData<TData>, TError>) => void) => {
    refetchPage(1, cb)
    removeOtherPages(1)
  }, [])

  useEffect(() => {
    let interval: number
    if (refOptions.current.refetchInterval) {
      const refetchInterval =
        typeof refOptions.current.refetchInterval === 'function'
          ? refOptions.current.refetchInterval(queryClient.getQueryCache().find({ queryKey: getQueryKey() }) as any)
          : refOptions.current.refetchInterval

      if (refetchInterval) {
        interval = window.setInterval(() => {
          if (!getIsRefetching()) {
            refetchCurrentPage()
          }
        }, refetchInterval)
      }
    }
    return () => window.clearInterval(interval)
  }, [refResult.current.data, refOptions.current.refetchInterval])

  const currentPageResult = refResult.current.data?.pages?.[getCurrentPage()]
  const currentPageData = (paginationModel(refResult.current.data?.pages?.[getCurrentPage()])?.results || []) as Results
  const dataList = flatten(refResult.current.data?.pages.map((page) => paginationModel(page)?.results) || []) as Results

  const isFetching = refResult.current.isFetching && !getIsRefetching()
  const isFetchingNextPage = getIsFetchingNextPage()
  const isFetchingAllPages = getIsFetchingAllPages()
  const isRefetching = refResult.current.isRefetching || getIsRefetching()
  const isLoading = (refResult.current.isLoading || isFetching) && !isFetchingNextPage && !isFetchingAllPages

  return {
    ...refResult.current,
    currentPageResult,
    currentPageData,
    dataList,
    fetchPage,
    fetchNextPage,
    fetchAllPages,
    refetchPage,
    refetchCurrentPage,
    hasNextPage,
    isLoading,
    isPending: isLoading,
    isFetchingNextPage,
    isFetchingAllPages,
    isFetching,
    isRefetching,
    currentPage: getCurrentPage(),
    setCurrentPage,
    removeOtherPages,
    reloadPages,
    pagination: {
      current: getCurrentPage(),
      total: getTotal(),
      pageSize: refOptions.current?.perPage,
      onChange: (page: number) => {
        fetchPage(page)
      },
    },
  }
}
