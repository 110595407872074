import { FC } from 'react'

import { MenuMarketAdmin } from 'components/template/Sidebar/MenuMarketAdmin'

import classes from './SidebarMenu.module.scss'

export const SidebarMenu: FC = () => (
  <div className={classes.sidebarMenu}>
    <MenuMarketAdmin />
  </div>
)
