import { FC } from 'react'

import { Flex } from '@radix-ui/themes'
import { useTranslate } from 'config/i18n'
import { useRevokeInviteToUser } from 'pro/employees/queries'
import { Button } from 'ui/Button'

import classes from './ToastSuccessResendInvite.module.scss'

export interface PropsToastSuccessResendInvite {
  employeeId: string
}

export const ToastSuccessResendInvite: FC<PropsToastSuccessResendInvite> = ({ employeeId }) => {
  const translate = useTranslate()
  const { revoke, isLoading } = useRevokeInviteToUser()

  return (
    <Flex gap="2">
      <div>{translate('pro.employee.invitationResented')}</div>
      <Button className={classes.button} isLoading={isLoading} onClick={() => revoke(employeeId)}>
        {translate('pro.employee.invitationRevoke')}
      </Button>
    </Flex>
  )
}
