import Axios from 'axios'
import { IMG_API_URL } from 'constants/env'
import { transformResponseToCamelCase } from 'packages/api'

const headersFormData = {
  'Content-Type': 'multipart/form-data',
}
const imgEndpoint = {
  uploadFile: () => 'upload',
}

const axios = Axios.create({
  baseURL: IMG_API_URL,
  transformResponse: transformResponseToCamelCase,
  headers: headersFormData,
})

interface UploadResponse {
  object: string
}

const uploadFile = async (file: File) => {
  const { data } = await axios.request<UploadResponse>({
    method: 'POST',
    url: imgEndpoint.uploadFile(),
    data: { file },
  })

  return data
}

const uploadFiles = async (file: File) =>
  axios.request<UploadResponse>({
    method: 'POST',
    url: imgEndpoint.uploadFile(),
    data: { file },
  })

export const imgApi = {
  uploadFile,
  uploadFiles,
}
