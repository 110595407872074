import { devtools } from 'config/devtools'
import { create } from 'zustand'

interface UseIsAuth {
  auth: boolean
  setAuth: (auth: boolean) => void
}

export const useIsAuth = create<UseIsAuth>()(
  devtools(
    (set) => ({
      auth: false,
      setAuth: (auth) => set({ auth }),
    }),
    {
      name: 'auth',
    },
  ),
)
