import { attributesApi } from 'admin/attributes/api/methods'
import { usePaginateQuery } from 'packages/react-query'

import { attributeTypesQuery } from './queryKeys'

export const useAttributeTypes = () => {
  const { dataList, isPending, error, pagination, fetchNextPage } = usePaginateQuery({
    queryKey: [attributeTypesQuery],
    queryFn: ({ pageParam }) => attributesApi.getAttributeTypes({ page: pageParam }),
    meta: { error: { showToast: false } },
    perPage: 50,
  })

  return {
    attributeTypes: dataList,
    isLoadingTypes: isPending,
    error,
    pagination,
    fetchNextPage,
  }
}
