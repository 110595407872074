import { axios } from 'config/axios'
import { GetAllData, GetAllResponse, GetOneData, GetOneResponse, Pagination } from 'interfaces/api.interfaces'
import { deleteEmpty } from 'packages/helper'
import { defaultPagination } from 'utils/pagination'

import { usersEndpoints } from './endpoints'
import { UsersCreateFormData, UsersForList, UsersFormData } from './interface'

const getUsers = async (pagination: Pagination, filterName?: string): GetAllResponse<UsersForList> => {
  const { data } = await axios.request<GetAllData<UsersForList>>({
    method: 'GET',
    url: usersEndpoints.getUsersList(),
    params: deleteEmpty({ ...defaultPagination(pagination, 50), filter: { name: filterName } }),
  })
  return data
}

const getUser = async (id: number): GetOneResponse<UsersForList> => {
  const { data } = await axios.request<GetOneData<UsersForList>>({
    method: 'GET',
    url: usersEndpoints.getUser(id),
  })
  return data
}

const createUser = async (form: UsersCreateFormData) => {
  const { data } = await axios.request<GetOneData<UsersForList>>({
    method: 'POST',
    url: usersEndpoints.createUser(),
    data: form,
  })

  return data
}

const updateUser = async (userId: number, form: UsersFormData) => {
  const { data } = await axios.request({
    method: 'PUT',
    url: usersEndpoints.editUser(userId),
    data: form,
  })

  return data
}

const deleteUser = async (userId: number) => {
  await axios.request<Promise<void>>({
    method: 'DELETE',
    url: usersEndpoints.deleteUser(userId),
  })
}

export const usersApi = {
  getUsers,
  createUser,
  getUser,
  updateUser,
  deleteUser,
}
