import { createContext, ReactNode, useRef } from 'react'

type AppContextType = {
  getOnLogout: () => () => void
  setOnLogout: (arg: () => void) => void
}

interface AppProviderProps {
  children: ReactNode
}

export const AppContext = createContext<AppContextType>({
  getOnLogout: () => () => {},
  setOnLogout: () => {},
})

export const AppProvider = ({ children }: AppProviderProps): ReactNode => {
  const refOnLogout = useRef<() => void>(() => {})

  const getOnLogout = () => refOnLogout.current

  const setOnLogout = (onLogout: () => void) => {
    refOnLogout.current = onLogout
  }

  return <AppContext.Provider value={{ getOnLogout, setOnLogout }}>{children}</AppContext.Provider>
}
