import { useState } from 'react'

import { createPluralize, I18N, useI18N as useI18nBase, useTranslate as useTranslateBase } from '@ayub-begimkulov/i18n'
import { useAsyncEffect } from 'ahooks'
import { devtools } from 'config/devtools'
import { getLang } from 'config/lang'
import { systemLang } from 'config/systemLang'
import { ArrayElement, UnionToIntersection } from 'interfaces/common.interfaces'
import { Languages } from 'interfaces/translations.interfaces'
import { keySetsRu } from 'locale/ru'
import { create } from 'zustand'
import { persist, subscribeWithSelector } from 'zustand/middleware'

type Translation = string | Record<string, string>
type Keyset = Record<string, Translation>

async function mergeJsonFiles<T extends Promise<{ default: Keyset }>>(promises: readonly T[]) {
  const jsons = await Promise.all(promises).then((items) => items.map((json) => json.default))
  return jsons.reduce((result, json) => ({ ...result, ...json }), {}) as UnionToIntersection<
    Awaited<ArrayElement<readonly T[]>>['default']
  >
}

const loadersLang = {
  ru: () => mergeJsonFiles(keySetsRu.map((fn) => fn())),
}

const pluralizeRu = createPluralize('ru')

export const i18n = new I18N({
  defaultLang: getLang(),
  languages: {
    ru: {
      keyset: loadersLang.ru,
      pluralize: pluralizeRu,
    },
  },
})

// @ts-ignore
export const useTranslate = useTranslateBase<typeof i18n>
export const useI18n = useI18nBase<typeof i18n>

export const useReadyLang = () => {
  const [ready, setReady] = useState(false)

  useAsyncEffect(async () => {
    await loadersLang[getLang()]().then(() => setReady(true))
  }, [])

  return { readyI18n: ready }
}

interface UseLang {
  lang: Languages
  setLang: (lang: Languages) => void
}

export const useLang = create<UseLang>()(
  subscribeWithSelector(
    devtools(
      persist(
        (set) => ({
          lang: systemLang,
          setLang: (lang) => {
            i18n.setLang(lang)
            set({ lang })
          },
        }),
        {
          name: 'lang',
        },
      ),
      {
        store: 'lang',
      },
    ),
  ),
)
