import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface UseAttributeTypesForm {
  collapsed: boolean
  setOpenForm: () => void
  setCloseForm: () => void
}

export const useAttributeTypeForm = create<UseAttributeTypesForm>()(
  devtools(
    persist(
      (set) => ({
        collapsed: false,
        setOpenForm: () => set(() => ({ collapsed: true })),
        setCloseForm: () => set(() => ({ collapsed: false })),
      }),
      {
        name: 'attributeTypesForm',
      },
    ),
    {
      store: 'attributeTypesForm',
    },
  ),
)
