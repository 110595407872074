import { FC } from 'react'

import { AttributeTypeContext } from 'admin/attributes/components/AttributeTypeContext'
import { AttributeTypeForm } from 'admin/attributes/components/AttributeTypeForm'
import { useTranslate } from 'config/i18n'
import { Button } from 'ui/Button'
import { Dialog } from 'ui/Dialog'

import classes from './AddAttributeTypeButton.module.scss'

export const AddAttributeTypeButton: FC = () => {
  const translate = useTranslate()

  return (
    <Dialog
      trigger={
        <Button className={classes.button} size="2" variant="soft">
          {translate('add')}
        </Button>
      }
    >
      <AttributeTypeContext isNew>
        <AttributeTypeForm isNew />
      </AttributeTypeContext>
    </Dialog>
  )
}
