import { useQuery } from '@tanstack/react-query'
import storage from 'config/storage'
import { getErrorMessage } from 'packages/react-query'
import { proCompanyApi } from 'pro/companies/api/methods'

export const useGetCompanyInfo = () => {
  const token = storage.getToken()

  const { data, isLoading, error } = useQuery({
    queryKey: ['authCompany', token],
    queryFn: () => proCompanyApi.getCompanyInfo(),
    meta: {
      error: (apiError) => ({
        showToast: false,
        message: getErrorMessage(apiError),
      }),
    },
  })

  return {
    getCompanyInfo: data,
    isLoading,
    error,
  }
}
