import { toast } from 'react-toastify'

import { useMutation } from '@tanstack/react-query'
import { useTranslate } from 'config/i18n'
import { queryClient } from 'config/queryClient'
import { useCompanyId } from 'pro/companies/store'
import { proListEmployeesApi } from 'pro/employees/api/methods'
import { usersQuery } from 'pro/employees/queries/queryKeys'

export const useRevokeInviteToUser = () => {
  const translate = useTranslate()
  const { companyId } = useCompanyId()

  const {
    mutate: revoke,
    isPending: isLoading,
    error,
  } = useMutation({
    mutationFn: (employeeId: string) => proListEmployeesApi.revokeInviteToUser(employeeId, companyId),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [usersQuery] })
      toast.success(translate('pro.employee.invitationRevoked'))
    },
    onError: () => {
      toast.error(translate('apiErrors.revokeInviteError'))
    },
  })

  return {
    revoke,
    isLoading,
    error,
  }
}
