const env = typeof process !== 'undefined' ? process.env : import.meta.env

export const IS_ADMIN = env.VITE_IS_ADMIN === 'true'
export const IS_PRO = env.VITE_IS_PRO === 'true'

export const IS_DEV_MODE = env.MODE === 'development'

export const {
  VITE_API_URL: API_URL,
  VITE_IMG_API_URL: IMG_API_URL,
  VITE_APP_NAME: APP_NAME,
  TEST: isTest,
  VITE_ACCENT_COLOR: ACCENT_COLOR,
} = env
