import { FC, ReactNode, useState } from 'react'

import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { useEventListener } from 'ahooks'
import CloseIcon from 'assets/images/icon-close.svg?react'
import { Button } from 'ui/Button'

import classes from './Dialog.module.scss'

interface PropsDialog {
  trigger?: ReactNode
  title?: string
  children?: ReactNode
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  description?: string
}

export const Dialog: FC<PropsDialog> = ({ isOpen, setIsOpen, trigger, title, children, description }) => {
  const [open, setOpen] = useState(false)
  useEventListener('closeDialog' as any, () => setOpen(false))

  return (
    <div className={classes.container}>
      <AlertDialog.Root onOpenChange={setIsOpen ? setIsOpen : setOpen} open={isOpen === undefined ? open : isOpen}>
        <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
        <AlertDialog.Portal container={document.body.querySelector('#portal2') as HTMLElement}>
          <AlertDialog.Overlay
            className={classes.alertDialogOverlay}
            data-stop-propagation="true"
            onClick={() => {
              if (setIsOpen) {
                setIsOpen(false)
              } else {
                setOpen(false)
              }
            }}
          />
          <AlertDialog.Content className={classes.alertDialogContent} data-stop-propagation="true">
            {title && <AlertDialog.Title className={classes.alertDialogTitle}>{title}</AlertDialog.Title>}
            {description && (
              <AlertDialog.Description asChild>
                <div className={classes.alertDialogDescription}>{description}</div>
              </AlertDialog.Description>
            )}
            {children}
            <Button
              className={classes.iconClose}
              color="gray"
              onClick={() => {
                if (setIsOpen) {
                  setIsOpen(false)
                } else {
                  setOpen(false)
                }
              }}
              variant="soft"
            >
              <CloseIcon />
            </Button>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  )
}
