import { FC, ReactNode } from 'react'

import { StreamAdmin } from 'admin/shared/StreamAdmin'
import { IS_ADMIN } from 'constants/env'
import { StreamPro } from 'pro/shared/StreamPro'

interface Props {
  children: ReactNode
}

export const Stream: FC<Props> = ({ children }) =>
  IS_ADMIN ? <StreamAdmin>{children}</StreamAdmin> : <StreamPro>{children}</StreamPro>
