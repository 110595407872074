import { devtools } from 'config/devtools'
import { User } from 'pro/auth/api'
import { create } from 'zustand'

interface UseUser {
  user: User
  setUser: (user: User) => void
}

export const useUser = create<UseUser>()(
  devtools(
    (set) => ({
      user: {
        id: '',
        phone: '',
        name: '',
        surname: '',
        email: '',
        avatar: '',
      },
      setUser: (user) => set({ user }),
    }),
    { name: 'useUser' },
  ),
)
