import { NoParamEndpointConstructor, OneParamEndpointConstructor } from 'interfaces/api.interfaces'

interface Endpoints {
  addNewCompany: NoParamEndpointConstructor
  getCompanyInfo: NoParamEndpointConstructor
  getOneCompanyInfo: OneParamEndpointConstructor
  updateCompany: OneParamEndpointConstructor
}

export const proCompanyEndpoints: Endpoints = {
  addNewCompany: () => 'auth/company',
  getCompanyInfo: () => 'auth/company/my',
  getOneCompanyInfo: (companyId) => `auth/company/${companyId}`,
  updateCompany: (companyId) => `auth/company/${companyId}`,
}
