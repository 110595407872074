import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UseAttributeTypesForm {
  createValue: boolean
  setCreateValue: (value: boolean) => void
}

export const useCreateAttributeValueButton = create<UseAttributeTypesForm>()(
  devtools(
    persist(
      (set) => ({
        createValue: false,
        setCreateValue: (value: boolean) => set(() => ({ createValue: value })),
      }),
      {
        name: 'createValue',
      },
    ),
    {
      store: 'createValue',
    },
  ),
)
