import { FC } from 'react'

import { useNavigate } from '@tanstack/react-router'
import LogoTextPro from 'assets/images/florals24-black.svg?react'
import LogoWithoutText from 'assets/images/logo-circle.svg?react'
import LogoTextAdmin from 'assets/images/logo-text.svg?react'
import cx from 'clsx'
import { IS_ADMIN, IS_PRO } from 'constants/env'
import { useSidebar } from 'hooks/useSidebar'

import classes from './Logo.module.scss'

export const Logo: FC = () => {
  const collapsed = useSidebar((state) => state.collapsed)
  const navigate = useNavigate()

  return (
    <div
      className={classes.logo}
      onClick={() => {
        navigate({
          to: '/',
        })
      }}
    >
      <LogoWithoutText className={cx('svg-img', classes.logoSvg)} />

      {IS_ADMIN && <LogoTextAdmin className={cx(classes.logoText, { [classes.opacity]: collapsed })} />}
      {IS_PRO && <LogoTextPro className={cx(classes.logoTextPro, 'svg-img', { [classes.opacity]: collapsed })} />}
    </div>
  )
}
