import { usePaginateQuery, useQueryClient } from 'packages/react-query'
import { useCompanyId } from 'pro/companies/store'
import { proListEmployeesApi } from 'pro/employees/api/methods'

import { usersQuery } from './queryKeys'

export const useUsers = (filterName?: string, filterActive?: boolean) => {
  const queryClient = useQueryClient()
  const { companyId } = useCompanyId()

  const { isPending, error, data, pagination, currentPage, fetchNextPage, hasNextPage, isFetchingNextPage } =
    usePaginateQuery({
      queryKey: [usersQuery, filterName, filterActive, companyId],
      queryFn: ({ pageParam }) =>
        proListEmployeesApi.getUsers({ page: pageParam }, companyId, filterName, filterActive),
      perPage: 50,
    })

  const clearUsers = () => {
    queryClient.removeQueries({ queryKey: [usersQuery] })
  }

  return {
    isLoading: isPending,
    error,
    users: data?.pages[currentPage]?.data ?? [],
    clearUsers,
    pagination,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  }
}
