import { usersApi } from 'admin/users/api'
import { usePaginateQuery, useQueryClient } from 'packages/react-query'

import { usersQuery } from './queryKeys'

export const useUsers = (filterName?: string) => {
  const queryClient = useQueryClient()

  const { isPending, error, data, pagination, currentPage } = usePaginateQuery({
    queryKey: [usersQuery, filterName],
    queryFn: ({ pageParam }) => usersApi.getUsers({ page: pageParam }, filterName),
    meta: { error: { showToast: false } },
    perPage: 50,
  })

  const clearUsers = () => {
    queryClient.removeQueries({ queryKey: [usersQuery] })
  }

  return {
    isLoading: isPending,
    error,
    users: data?.pages[currentPage]?.data ?? [],
    clearUsers,
    pagination,
  }
}
