import { i18n } from 'config/i18n'

export const parametersStorehouse = () => [
  {
    id: 1,
    name: i18n.get('pro.receipts.title'),
    navigate: {
      to: '/receipts',
    },
  },
]
