import { i18n } from 'config/i18n'

export const parametersProductsAndServices = () => [
  {
    id: 0,
    name: i18n.get('pro.products.productCatalog'),
    navigate: {
      to: '/products',
    },
  },
  {
    id: 1,
    name: i18n.get('pro.products.productCategories'),
    navigate: {
      to: '/categories',
    },
  },
]
