import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { AttributeTypeFormData } from 'admin/attributes/api/interface'
import { attributesApi } from 'admin/attributes/api/methods'

import { attributeTypesQuery, oneAttributeTypeQuery } from './queryKeys'

export const useUpdateAttributeType = () => {
  const params = useParams({ from: '/attribute-types/$attributeTypeId' })
  const attributeTypeId = Number(params.attributeTypeId)

  const queryClient = useQueryClient()

  const { mutate, isPending, error, reset } = useMutation({
    mutationFn: async (form: AttributeTypeFormData) => {
      if (attributeTypeId) {
        return attributesApi.updateAttributeType(attributeTypeId, form)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [attributeTypesQuery] })
      queryClient.invalidateQueries({ queryKey: [oneAttributeTypeQuery] })
    },
    meta: {
      error: { showToast: false },
    },
  })

  return {
    saveAttributeType: mutate,
    isLoading: isPending,
    error,
    reset,
  }
}
