import { useQuery } from '@tanstack/react-query'
import { attributesApi } from 'admin/attributes/api/methods'
import { useQueryClient } from 'packages/react-query'

import { oneAttributeTypeQuery } from './queryKeys'

export const useAttributeType = (attributeTypeId: number | undefined) => {
  const queryClient = useQueryClient()

  const { isLoading, error, data } = useQuery({
    queryKey: [oneAttributeTypeQuery, attributeTypeId],
    queryFn: () => {
      if (attributeTypeId) {
        return attributesApi.getOneAttributeType(attributeTypeId)
      }
    },
    meta: { error: { showToast: false } },
  })

  const clearAttributeTypeState = () => {
    queryClient.removeQueries({ queryKey: [oneAttributeTypeQuery, attributeTypeId] })
  }

  return {
    attributeType: data?.data,
    isLoading,
    error,
    clearAttributeTypeState,
  }
}
