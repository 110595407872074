import { FC, useEffect, useState } from 'react'

import * as AlertDialog from '@radix-ui/react-alert-dialog'
import cx from 'clsx'
import { capitalize } from 'lodash'
import { Button } from 'ui/Button'

import classes from './Dialog.module.scss'

interface Props {
  cancelText?: string
  okText?: string
  onCloseDialog?: (arg: boolean) => void
  isLoading?: boolean
  submit?: boolean
  danger?: boolean
  onClickOk?: () => void
  closeDialogAfterSuccess?: boolean
  onClickCancel?: () => void
  buttonPosition?: 'left' | 'center' | 'right'
}

export const DialogButtons: FC<Props> = ({
  cancelText,
  okText,
  isLoading,
  submit,
  danger,
  onClickOk,
  closeDialogAfterSuccess = true,
  onClickCancel,
  onCloseDialog,
  buttonPosition = 'right',
}) => {
  const [isClicked, setIsClicked] = useState(false)

  const onOkInternal = () => {
    onClickOk?.()
    if (closeDialogAfterSuccess) {
      setTimeout(() => {
        setIsClicked(true)
      })
    }
  }

  const onCancelHandler = () => {
    if (onClickCancel) {
      onClickCancel()
    }
  }

  useEffect(() => {
    if (closeDialogAfterSuccess && isClicked && !isLoading) {
      setIsClicked(false)
      if (onCloseDialog) {
        onCloseDialog(false)
      } else {
        dispatchEvent(new Event('closeDialog'))
      }
    }
  }, [isClicked, isLoading, closeDialogAfterSuccess])

  return (
    <div className={cx(classes.dialogButton, classes[`position${capitalize(buttonPosition)}`])}>
      {onCancelHandler && cancelText && (
        <AlertDialog.Cancel asChild>
          <Button color="gray" onClick={onCancelHandler} size="3" variant="soft">
            {cancelText}
          </Button>
        </AlertDialog.Cancel>
      )}

      {onOkInternal && okText && (
        <Button
          color={danger ? 'red' : undefined}
          isLoading={isLoading}
          onClick={onOkInternal}
          size="3"
          type={submit ? 'submit' : undefined}
          variant="soft"
        >
          {okText}
        </Button>
      )}
    </div>
  )
}
