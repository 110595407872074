import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface UseSidebar {
  collapsed: boolean
  toggle: () => void
}

export const useSidebar = create<UseSidebar>()(
  devtools(
    persist(
      (set) => ({
        collapsed: false,
        toggle: () => set(({ collapsed }) => ({ collapsed: !collapsed })),
      }),
      {
        name: 'sidebar',
      },
    ),
    {
      store: 'sidebar',
    },
  ),
)
