import { useQuery } from '@tanstack/react-query'
import { useTranslate } from 'config/i18n'
import { useIsAuth } from 'hooks/useIsAuth'
import { useQueryClient } from 'packages/react-query'
import { profileApi } from 'pro/sharedQueries/profile/api/methods'

export const useProfile = () => {
  const auth = useIsAuth((state) => state.auth)
  const translate = useTranslate()
  const queryClient = useQueryClient()

  const { isPending, error, data } = useQuery({
    queryKey: ['userProData'],
    queryFn: async () => profileApi.getProfilePro(),
    enabled: auth,
    meta: { error: { showToast: false, message: translate('apiErrors.profile') } },
  })

  const clearProfileState = () => {
    queryClient.removeQueries({ queryKey: ['userProData'] })
  }

  return {
    isLoading: isPending,
    error,
    profile: data,
    clearProfileState,
  }
}
