import { useContext } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { AppContext } from 'layouts/AppProvider'

export const useLogout = () => {
  const navigate = useNavigate()
  const { getOnLogout } = useContext(AppContext)

  const logout = () => {
    setAxiosAuthorizationToken(null)
    storage.clearToken()

    getOnLogout()()
    navigate({ to: '/login' })
  }

  return { logout }
}
