import { FC } from 'react'

import cx from 'clsx'
import { ApiError } from 'packages/api'
import { getErrorMessage } from 'packages/react-query'

import classes from './error.module.scss'

interface ErrorProps {
  error?: ApiError | null
  message?: string
  className?: string
}

export const Error: FC<ErrorProps> = ({ error, message, className }) => (
  <div className={cx(classes.error, className)}>{message ? message : getErrorMessage(error)}</div>
)
