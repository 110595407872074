import { FC, ReactNode, useContext, useEffect } from 'react'

import { AppContext } from 'layouts/AppProvider'
import { AuthSteps } from 'pro/auth/interfaces'
import { useAuth } from 'pro/auth/store'
import { useUsers } from 'pro/employees/queries'
import { useCurrentUser } from 'pro/employees/queries/useCurrentUser'
import { useProfile } from 'pro/sharedQueries/profile/queries'
import { Error } from 'ui/Error'
import { Loader, LoaderTypes } from 'ui/Loader'

import classes from './StreamPro.module.scss'

interface Props {
  children: ReactNode
}

export const StreamPro: FC<Props> = ({ children }) => {
  const { isLoading, error } = useProfile()
  const { setOnLogout } = useContext(AppContext)
  const { clearCurrentUserState } = useCurrentUser()
  const { clearUsers } = useUsers()
  const { setAuthStep } = useAuth()

  useEffect(() => {
    setOnLogout(() => {
      setAuthStep(AuthSteps.EnterPhone)
      clearCurrentUserState()
      clearUsers()
    })
  }, [])

  return (
    <>
      {isLoading && (
        <div className={classes.container}>
          <Loader type={LoaderTypes.SpinnerCenter} />
        </div>
      )}
      {error && (
        <div className={classes.container}>
          <Error error={error} />
        </div>
      )}
      {!isLoading && !error && <>{children}</>}
    </>
  )
}
