import { devtools } from 'config/devtools'
import { AuthSteps } from 'pro/auth/interfaces'
import { create } from 'zustand'

interface UseAuth {
  phoneNumber: string
  setPhoneNumber: (phoneNumber: string) => void
  smsCode: string
  setSmsCode: (smsCode: string) => void
  isAllowGetNewCode: boolean
  setIsAllowGetNewCode: (isAllowGetNewCode: boolean) => void
  getNewCodeInTimer: number
  setGetNewCodeInTimer: (getNewCodeInTimer: number) => void
  authStep: AuthSteps
  setAuthStep: (authStep: AuthSteps) => void
}

export const useAuth = create<UseAuth>()(
  devtools(
    (set) => ({
      phoneNumber: '',
      setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
      smsCode: '',
      setSmsCode: (smsCode) => set({ smsCode }),
      isAllowGetNewCode: true,
      setIsAllowGetNewCode: (isAllowGetNewCode) => set({ isAllowGetNewCode }),
      getNewCodeInTimer: 4,
      setGetNewCodeInTimer: (getNewCodeInTimer) => set({ getNewCodeInTimer }),
      authStep: AuthSteps.EnterPhone,
      setAuthStep: (authStep) => set({ authStep }),
    }),
    { name: 'useAuth' },
  ),
)
