import { createRootRoute, redirect } from '@tanstack/react-router'
import 'assets/scss/app.scss'
import { isAuthenticated } from 'config/auth/isAuthenticated'
import { isLoginPage } from 'config/auth/isLoginPage'
import { RootLayout } from 'layouts/RootLayout'

export const Route = createRootRoute({
  component: RootLayout,
  beforeLoad: async ({ location }) => {
    if (!isAuthenticated() && !isLoginPage(location)) {
      throw redirect({
        to: '/login',
        search:
          location.href === '/'
            ? undefined
            : {
                redirect: location.href,
              },
      })
    } else if (isAuthenticated() && isLoginPage(location)) {
      throw redirect({
        to: (location.search as any)?.redirect || '/',
      })
    }
  },
})
