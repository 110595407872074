import { FC, PropsWithChildren } from 'react'

import { Sidebar } from 'components/template/Sidebar'
import { AppProvider } from 'layouts/AppProvider'

import { Stream } from './components/Stream'
import classes from './MainLayout.module.scss'

export const MainLayout: FC<PropsWithChildren> = ({ children }) => (
  <AppProvider>
    <Stream>
      <section className={classes.wrap}>
        <Sidebar />
        <main className={classes.main}>
          <div className={classes.page}>{children}</div>
        </main>
      </section>
    </Stream>
  </AppProvider>
)
