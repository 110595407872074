import { useQuery } from '@tanstack/react-query'
import { useQueryClient } from 'packages/react-query'
import { proCompanyApi } from 'pro/companies/api/methods'

import { oneCompanyQuery } from './queryKey'

export const useGetOneCompanyInfo = (companyId: string | undefined) => {
  const queryClient = useQueryClient()

  const { isLoading, error, data } = useQuery({
    queryKey: [oneCompanyQuery, companyId],
    queryFn: () => {
      if (companyId) {
        return proCompanyApi.getOneCompanyInfo(companyId)
      }
    },
    meta: { error: { showToast: false } },
  })

  const clearOneCompanyState = () => {
    queryClient.removeQueries({ queryKey: [companyId] })
  }

  return {
    oneCompanyInfo: data,
    isLoading,
    error,
    clearOneCompanyState,
  }
}
