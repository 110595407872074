import { FC, ReactNode } from 'react'

import { Text } from '@radix-ui/themes'
import { Link, useNavigate } from '@tanstack/react-router'
import cx from 'clsx'
import { useSidebar } from 'hooks/useSidebar'

import classes from './MenuItemNavigate.module.scss'

interface MenuItemProps {
  href?: string
  icon?: ReactNode
  children?: ReactNode
  onClickNavigate?: (attributeTypeId: string) => void
  caretIcon?: ReactNode
  itemId: number
  isSelected?: boolean
  isActive?: boolean
}

export const MenuItemNavigate: FC<MenuItemProps> = ({
  href,
  icon,
  children,
  caretIcon,
  isSelected = false,
  isActive,
}) => {
  const collapsed = useSidebar((state) => state.collapsed)
  const navigate = useNavigate()

  const handleClick = () => {
    navigate({ to: href })
  }

  return (
    <li
      className={cx(
        classes.item,
        {
          [classes.collapsed]: collapsed,
          [classes.selected]: isSelected,
        },
        { [classes.isActive]: isActive },
      )}
      onClick={handleClick}
    >
      <Link className={classes.link} to={href}>
        {icon && <span className={cx(classes.icon, { [classes.collapsed]: collapsed })}>{icon}</span>}
        <Text className={cx(classes.text, { [classes.collapsed]: collapsed }, { [classes.isActive]: isActive })}>
          {children}
        </Text>
        {caretIcon}
      </Link>
    </li>
  )
}
