import { GetAllData } from 'interfaces/api.interfaces'

export interface PaginationResult<Item = unknown> {
  count: number
  next: number | null
  results: Item[]
  page?: number | null
  perPage?: number
}

export const createPaginationModel =
  <Item = unknown>(cb: (data: GetAllData<Item>) => PaginationResult<Item>) =>
  (data: GetAllData<Item> | undefined) =>
    data === undefined ? undefined : cb(data)

export const defaultPaginationModel = createPaginationModel((data) => {
  const getNextPageParam = () => {
    const lastPage = data.meta.lastPage
    const currentPage = data.meta.currentPage
    return lastPage && currentPage && lastPage > currentPage ? currentPage + 1 : null
  }

  return {
    count: data.meta.total,
    next: getNextPageParam(),
    results: data.data,
    page: data.meta.currentPage,
    perPage: data.perPage,
  }
})
