import { axios } from 'config/axios'
import { GetAllData, GetAllResponse, GetOneData, GetOneResponse, Pagination } from 'interfaces/api.interfaces'
import { deleteEmpty } from 'packages/helper'
import { defaultPagination } from 'utils/pagination'

import { attributesEndpoints } from './endpoints'
import { AttributeTypeForList, AttributeTypeFormData, AttributeValue, AttributeValueFormData } from './interface'

const getAttributeTypes = async (pagination: Pagination): GetAllResponse<AttributeTypeForList> => {
  const { data } = await axios.request<GetAllData<AttributeTypeForList>>({
    method: 'GET',
    url: attributesEndpoints.getAttributeTypes(),
    params: deleteEmpty({ ...defaultPagination(pagination, 50) }),
  })
  return data
}

const getOneAttributeType = async (id: number): GetOneResponse<AttributeTypeForList> => {
  const { data } = await axios.request<GetOneData<AttributeTypeForList>>({
    method: 'GET',
    url: attributesEndpoints.getOneAttributeType(id),
  })
  return data
}

const createAttributeType = async (form: AttributeTypeFormData): GetOneResponse<AttributeTypeForList> => {
  const { data } = await axios.request<GetOneData<AttributeTypeForList>>({
    method: 'POST',
    url: attributesEndpoints.createAttributeType(),
    data: form,
  })

  return data
}

const updateAttributeType = async (attributeTypeId: number, form: AttributeTypeFormData) => {
  const { data } = await axios.request({
    method: 'PUT',
    url: attributesEndpoints.updateAttributeType(attributeTypeId),
    data: form,
  })

  return data
}

const deleteAttributeType = async (attributeTypeId: number) => {
  await axios.request<Promise<void>>({
    method: 'DELETE',
    url: attributesEndpoints.deleteAttributeType(attributeTypeId),
  })
}

const getAttributeValues = async (id: number, pagination: Pagination): GetAllResponse<AttributeValue> => {
  const { data } = await axios.request<GetAllData<AttributeValue>>({
    method: 'GET',
    url: attributesEndpoints.getAttributeValues(id),
    params: deleteEmpty({ ...defaultPagination(pagination, 50) }),
  })
  return data
}

const getOneAttributeValue = async (id: number): GetOneResponse<AttributeValue> => {
  const { data } = await axios.request<GetOneData<AttributeValue>>({
    method: 'GET',
    url: attributesEndpoints.getOneAttributeValue(id),
  })
  return data
}

const createAttributeValue = async (
  attributeTypeId: number,
  form: AttributeValueFormData,
): GetOneResponse<AttributeValue> => {
  const { data } = await axios.request<GetOneData<AttributeValue>>({
    method: 'POST',
    url: attributesEndpoints.createAttributeValue(attributeTypeId),
    data: form,
  })

  return data
}

const updateAttributeValue = async (attributeValueId: number, form: AttributeValueFormData) => {
  const { data } = await axios.request({
    method: 'PUT',
    url: attributesEndpoints.updateAttributeValue(attributeValueId),
    data: form,
  })

  return data
}

const deleteAttributeValue = async (attributeValueId: number) => {
  await axios.request<Promise<void>>({
    method: 'DELETE',
    url: attributesEndpoints.deleteAttributeValue(attributeValueId),
  })
}

export const attributesApi = {
  getAttributeTypes,
  getOneAttributeType,
  createAttributeType,
  deleteAttributeType,
  updateAttributeType,
  getAttributeValues,
  getOneAttributeValue,
  createAttributeValue,
  deleteAttributeValue,
  updateAttributeValue,
}
