import { NoParamEndpointConstructor, OneParamEndpointConstructor } from 'interfaces/api.interfaces'

interface Endpoints {
  getUsersList: NoParamEndpointConstructor
  getUser: OneParamEndpointConstructor
  createUser: NoParamEndpointConstructor
  editUser: OneParamEndpointConstructor
  deleteUser: OneParamEndpointConstructor
}

export const usersEndpoints: Endpoints = {
  getUsersList: () => 'admin/users/',
  getUser: (id) => `admin/users/${id}`,
  createUser: () => 'admin/users',
  editUser: (id) => `admin/users/${id}`,
  deleteUser: (id) => `admin/users/${id}`,
}
