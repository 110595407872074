import { FC, useState } from 'react'

import { useAttributeTypes } from 'admin/attributes/queries'
import CartIcon from 'assets/images/cart.svg?react'
import CityIcon from 'assets/images/city.svg?react'
import Users from 'assets/images/users.svg?react'
import { MenuItem } from 'components/template/Sidebar/MenuItem'
import { MenuItemList } from 'components/template/Sidebar/MenuItemList'
import { useTranslate } from 'config/i18n'

import classes from './MenuMarketAdmin.module.scss'

export const MenuMarketAdmin: FC = () => {
  const translate = useTranslate()
  const { attributeTypes } = useAttributeTypes()

  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null)

  const onMenuItemClick = (item: string) => {
    setSelectedMenuItem(item)
  }

  return (
    <ul className={classes.menu}>
      <MenuItem
        href="/products"
        icon={<CartIcon />}
        isActive={selectedMenuItem === '/products'}
        onClick={() => onMenuItemClick('/products')}
      >
        {translate('menubar.products')}
      </MenuItem>
      <MenuItem
        href="/cities"
        icon={<CityIcon />}
        isActive={selectedMenuItem === '/cities'}
        onClick={() => onMenuItemClick('/cities')}
      >
        {translate('cities.menuItem')}
      </MenuItem>
      <MenuItemList
        items={attributeTypes}
        onMenuItemClick={onMenuItemClick}
        routesItem="/attribute-types"
        translate={translate('attributeTypes.parameters')}
      />
      <MenuItem
        href="/users"
        icon={<Users />}
        isActive={selectedMenuItem === '/users'}
        onClick={() => onMenuItemClick('/users')}
      >
        {translate('users.users')}
      </MenuItem>
    </ul>
  )
}
