import { i18n } from 'config/i18n'
import { ApiErrorServer } from 'interfaces/api.interfaces'
import { ApiError, ApiTypingErrors } from 'packages/api'

export enum ApiErrorsCodes {
  NotFound = 'not_found',
  NotValid = 'not_valid',
  UnknownError = 'unknown_error',
}

export const apiTypingErrors = new ApiTypingErrors<ApiError, ApiErrorServer>({
  initialError: () => ({
    message: i18n.get('apiErrors.unknownError'),
    code: ApiErrorsCodes.UnknownError,
  }),
  notFoundError: () => ({
    message: i18n.get('apiErrors.notFound'),
    code: ApiErrorsCodes.NotFound,
  }),
  validationReplaceToCamelCase: true,
})

export const { errorInterceptor } = apiTypingErrors
