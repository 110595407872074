import { axios } from 'config/axios'
import storage from 'config/storage'
import { deleteNullable, toSnakeCase } from 'packages/helper'
import { objectToFormData } from 'utils/objectToFormData'

import { proCompanyEndpoints } from './endpoints'
import { CompanyFormData, CompanySuggestion } from './interfaces'

const addNewCompany = async (form: CompanyFormData): Promise<CompanyFormData> => {
  const accessToken = storage.getToken()
  const { data } = await axios.request<CompanyFormData>({
    method: 'POST',
    url: proCompanyEndpoints.addNewCompany(),
    data: objectToFormData(toSnakeCase({ ...form, number: String(form.number) })),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return data
}

const getCompanyInfo = async (): Promise<CompanySuggestion[]> => {
  const accessToken = storage.getToken()

  const { data } = await axios.request<CompanySuggestion[]>({
    method: 'GET',
    url: proCompanyEndpoints.getCompanyInfo(),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return data
}

const getOneCompanyInfo = async (companyId: string) => {
  const { data } = await axios.request({
    method: 'GET',
    url: proCompanyEndpoints.getOneCompanyInfo(companyId),
  })

  return data
}

const updateCompany = async (companyId: string, form: CompanyFormData) => {
  const { data } = await axios.request({
    method: 'PUT',
    url: proCompanyEndpoints.updateCompany(companyId),
    data: deleteNullable(form),
  })

  return data
}

export const proCompanyApi = {
  addNewCompany,
  getCompanyInfo,
  getOneCompanyInfo,
  updateCompany,
}
