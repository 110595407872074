import { axios } from 'config/axios'

import { profileEndpoints } from './endpoints'
import { Profile } from './interfaces'

const getProfilePro = async (): Promise<Profile> => {
  const { data } = await axios.request<Profile>({
    method: 'GET',
    url: profileEndpoints.profilePro(),
  })
  return data
}

export const profileApi = {
  getProfilePro,
}
