import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

interface Endpoints {
  login: NoParamEndpointConstructor
  refresh: NoParamEndpointConstructor
  loginPro: NoParamEndpointConstructor
  refreshPro: NoParamEndpointConstructor
}

export const authEndpoints: Endpoints = {
  login: () => 'auth/issue-access-token',
  refresh: () => 'auth/refresh-access-token',
  loginPro: () => 'auth/authorization/me',
  refreshPro: () => 'auth/authorization/refresh',
}
