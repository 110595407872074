import { useQuery } from '@tanstack/react-query'
import { queryClient } from 'config/queryClient'
import { useCompanyId } from 'pro/companies/store'
import { proListEmployeesApi } from 'pro/employees/api/methods'

import { currentUserQuery } from './queryKeys'

export const useCurrentUser = () => {
  const { companyId } = useCompanyId()

  const { isLoading, error, data } = useQuery({
    queryKey: [currentUserQuery, companyId],
    queryFn: () => proListEmployeesApi.getCurrentUser(companyId),
  })

  const clearCurrentUserState = () => {
    queryClient.removeQueries({ queryKey: [currentUserQuery] })
  }

  return {
    currentUser: data,
    isLoading,
    error,
    clearCurrentUserState,
  }
}
