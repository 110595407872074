import { authEndpoints } from 'admin/auth/api'
import { axios, setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { IS_ADMIN } from 'constants/env'
import { storageRefreshName } from 'constants/storage'
import { RefreshData, TokenResponse } from 'interfaces/auth.interfaces'

const refresh = async (form: RefreshData): Promise<TokenResponse> => {
  const { data } = await axios.request<TokenResponse>({
    method: 'POST',
    url: IS_ADMIN ? authEndpoints.refresh() : authEndpoints.refreshPro(),
    data: form,
  })
  return data
}

export const refreshTokens = async () => {
  const refreshToken = storage.getRefresh()

  const tokens = await refresh({ refreshToken }).catch((error) => {
    throw { ...error, code: 'refreshError' }
  })

  const tokenLS = window.localStorage.getItem(storageRefreshName)
  storage.setToken(tokens.accessToken, !!tokenLS)
  storage.setRefresh(tokens.refreshToken, !!tokenLS)
  setAxiosAuthorizationToken(tokens.accessToken)

  return { tokens }
}
