import { FC, ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from '@tanstack/react-router'
import { AttributeTypeFormData } from 'admin/attributes/api/interface'
import { useAttributeType } from 'admin/attributes/queries'
import { attributeTypeSchema } from 'admin/helpers/validation'

interface Props {
  children: ReactNode
  isNew?: boolean
}

export const AttributeTypeContext: FC<Props> = ({ children, isNew }) => {
  const params = useParams({ from: '/attribute-types/$attributeTypeId' })
  const { attributeType } = useAttributeType(!isNew ? Number(params.attributeTypeId) : undefined)
  const methods = useForm<AttributeTypeFormData>({
    resolver: yupResolver(attributeTypeSchema),
    defaultValues: {
      name: '',
      slug: '',
      order: 0,
    },
  })

  const { reset } = methods

  useEffect(() => {
    if (attributeType) {
      reset(attributeType)
    }
  }, [attributeType])

  return <FormProvider {...methods}>{children}</FormProvider>
}
