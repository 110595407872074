import { systemLang } from 'config/systemLang'
import { storageAuthName, storageLang, storageRefreshName, storageTokenName } from 'constants/storage'
import { Languages } from 'interfaces/translations.interfaces'

interface Storage {
  setItem: <T>(name: string, data: T) => void
  getItem: <T>(name: string) => T | null
  removeItem: (key: string) => void
  getToken: () => string
  setToken: (token: string, remember?: boolean) => void
  getRefresh: () => string
  setRefresh: (refresh: string, remember?: boolean) => void
  clearToken: () => void
  getLang: () => Languages
  onSetToken: null | ((remember?: boolean) => void)
  onClearToken: null | (() => void)
}

const storage: Storage = {
  setItem: <T>(name: string, data: T): void => {
    const stringifiedData = JSON.stringify(data)
    window.localStorage.setItem(name, stringifiedData)
  },
  getItem: <T>(name: string): T | null => {
    const data = window.localStorage.getItem(name)
    if (!data) {
      return null
    }
    return JSON.parse(data) as T
  },
  removeItem: (key: string) => {
    window.localStorage.removeItem(key)
    window.sessionStorage.removeItem(key)
  },
  getToken: (): string => {
    const tokenLS = window.localStorage.getItem(storageTokenName) as string
    const tokenSession = window.sessionStorage.getItem(storageTokenName) as string
    return tokenLS ? tokenLS : tokenSession
  },
  setToken: (token: string, remember?: boolean): void => {
    const store = remember ? window.localStorage : window.sessionStorage
    const clearedStore = remember ? window.sessionStorage : window.localStorage
    store.setItem(storageTokenName, token)
    store.setItem(storageAuthName, JSON.stringify({ value: !!token }))
    clearedStore.removeItem(storageTokenName)
    clearedStore.removeItem(storageAuthName)
    storage.onSetToken?.(remember)
  },
  getRefresh: (): string => {
    const tokenLS = window.localStorage.getItem(storageRefreshName) as string
    const tokenSession = window.sessionStorage.getItem(storageRefreshName) as string
    return tokenLS ? tokenLS : tokenSession
  },
  setRefresh: (refresh: string, remember?: boolean): void => {
    const store = remember ? window.localStorage : window.sessionStorage
    const clearedStore = remember ? window.sessionStorage : window.localStorage
    store.setItem(storageRefreshName, refresh)
    clearedStore.removeItem(storageRefreshName)
  },
  clearToken: (): void => {
    window.localStorage.removeItem(storageLang)
    window.localStorage.removeItem(storageTokenName)
    window.localStorage.removeItem(storageAuthName)
    window.localStorage.removeItem(storageRefreshName)
    window.sessionStorage.removeItem(storageTokenName)
    window.sessionStorage.removeItem(storageAuthName)
    window.sessionStorage.removeItem(storageRefreshName)
    storage.onClearToken?.()
  },
  getLang: (): Languages => {
    const lang = window.localStorage.getItem(storageLang)
    if (!lang) {
      return systemLang
    }
    return (JSON.parse(lang)?.state?.lang as Languages) || systemLang
  },
  onSetToken: null,
  onClearToken: null,
}

export default storage
