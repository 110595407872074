import { useMutation } from '@tanstack/react-query'
import { AttributeTypeFormData } from 'admin/attributes/api/interface'
import { attributesApi } from 'admin/attributes/api/methods'
import { useQueryClient } from 'packages/react-query'

import { attributeTypesQuery, oneAttributeTypeQuery } from './queryKeys'

export const useCreateAttributeType = () => {
  const queryClient = useQueryClient()

  const { mutate, isPending, error } = useMutation({
    mutationFn: (form: AttributeTypeFormData) => attributesApi.createAttributeType(form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [attributeTypesQuery] })
      queryClient.invalidateQueries({ queryKey: [oneAttributeTypeQuery] })
    },
  })

  return {
    addAttributeType: mutate,
    isLoading: isPending,
    errors: error,
  }
}
