import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UseCompanyId {
  companyId: string
  setCompanyId: (authCompany: string) => void
}

export const useCompanyId = create<UseCompanyId>()(
  devtools(
    persist(
      (set) => ({
        companyId: '',
        setCompanyId: (companyId) => set({ companyId }),
      }),
      { name: 'pro.companies.currentCompany' },
    ),
    { store: 'pro.companies.currentCompany' },
  ),
)
