import { NoParamEndpointConstructor, OneParamEndpointConstructor } from 'interfaces/api.interfaces'

interface Endpoints {
  getUsers: NoParamEndpointConstructor
  activateUser: NoParamEndpointConstructor
  deactivateUser: NoParamEndpointConstructor
  getUser: OneParamEndpointConstructor
  editUser: OneParamEndpointConstructor
  getCurrentUser: NoParamEndpointConstructor
  createUser: NoParamEndpointConstructor
  resendInviteUser: OneParamEndpointConstructor
  revokeInviteUser: OneParamEndpointConstructor
}

export const proUserEndpoints: Endpoints = {
  getUsers: () => '/app/user',
  activateUser: () => 'app/user/activate',
  deactivateUser: () => 'app/user/block',
  getUser: (id) => `app/user/${id}`,
  editUser: (id) => `app/user/${id}`,
  getCurrentUser: () => '/app/user/me',
  createUser: () => 'app/user',
  resendInviteUser: (id) => `auth/user/${id}/invite/resend`,
  revokeInviteUser: (id) => `app/user/recall-invite/${id}`,
}
