import { FC } from 'react'

import { MenuMarketPro } from 'components/template/Sidebar/MenuMarketPro'

import classes from './SidebarMenuPro.module.scss'

export const SidebarMenuPro: FC = () => (
  <div className={classes.sidebarMenu}>
    <MenuMarketPro />
  </div>
)
