export enum AuthSteps {
  EnterPhone = 'enterPhone',
  EnterCode = 'enterCode',
  Register = 'register',
  NoCompanyFound = 'noCompanyFound',
  CreateCompany = 'createCompany',
  SelectCompany = 'selectCompany',
  Companies = 'companies',
  PersonalData = 'personalData',
}
